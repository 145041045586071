// Translated
// Migrated
<template lang="pug">
.floating-action-button(:class="{ '--expanded': expanded, [`--${position}`]: true }")
  .align-items-center.pt-3.px-3.pb-2.position-relative(
    :class="[{ 'd-flex': expanded }, `bg-${color}`]"
  )
    template(v-if="expanded")
      button.btn.btn-link.text-white.mr-3(
        type="button"
        @click="toggle"
      )
        fa(
          icon="chevron-up"
          width="16"
        )
      slot(
        name="header"
        v-bind="slotData"
      )
    .text-center(v-else)
      h4
        fa(
          :icon="icon"
          width="24"
        )
      hr
      button.btn.btn-link.text-white(
        type="button"
        @click="expanded = !expanded"
      )
        fa(
          icon="chevron-down"
          width="16"
        )

  .fab-body(v-if="expanded")
    slot(
      name="body"
      v-bind="slotData"
    )
</template>

<script>
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'

export default defineNuxtComponent({
  props: {
    icon: {
      type: [Object, String],
      default: faInfoCircle
    },

    color: {
      type: String,
      default: 'blue'
    },

    position: {
      type: String,
      default: 'right'
    }
  },

  data () {
    return {
      expanded: false
    }
  },

  computed: {
    slotData () {
      return {
        onToggle: this.toggle,
        expanded: this.expanded
      }
    }
  },

  methods: {
    toggle () {
      this.expanded = !this.expanded
    }
  }
})
</script>

<style lang="scss" scoped>
/*! purgecss start ignore */
.floating-action-button{
  position: fixed;
  top: 1rem;
  z-index: 10000;
  box-shadow: 0 0 2px rgb(202, 202, 202);

  color: white;

  @media (max-width: $md) {
    top: 3rem;
  }

  &.--expanded {
    top: 7rem;
  }
  &.--right {
    right: 1rem;
  }
  &.--left {
    left: 1rem;
  }

  .fab {
    &-body {
      padding: 1rem;
      background: white;
      color: $black;
    }
  }
}
/*! purgecss end ignore */
</style>
